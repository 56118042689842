import React from "react"
import * as styles from "./PasswordRequirements.module.scss"
import cn from "classnames"

interface PasswordRequirementsProps {
  classname?: string
}

export const PasswordRequirements = ({
  classname,
}: PasswordRequirementsProps) => {
  return (
    <div className={cn(styles.passwordRequirementsContainer, classname)}>
      <span>Minimum requirements:</span>
      <ul>
        <li>10 characters</li>
        <li>1 lower case</li>
        <li>1 upper case</li>
        <li>1 symbol</li>
      </ul>
    </div>
  )
}
