// extracted by mini-css-extract-plugin
export var createAccount = "CreateAccount-module--createAccount--87c7a";
export var createAccountButton = "CreateAccount-module--createAccountButton--5b98b";
export var createAccountConnectWalletButtons = "CreateAccount-module--createAccountConnectWalletButtons--ca26e";
export var createAccountFormBackground = "CreateAccount-module--createAccountFormBackground--0ea47";
export var createAccountPasswordContainer = "CreateAccount-module--createAccountPasswordContainer--68cdf";
export var createAccountPasswordRequirements = "CreateAccount-module--createAccountPasswordRequirements--dacd5";
export var createAccountSignUpButtons = "CreateAccount-module--createAccountSignUpButtons--0e819";
export var createAccountSignUpError = "CreateAccount-module--createAccountSignUpError--ab8d8";
export var createAccountSignUpErrorMessage = "CreateAccount-module--createAccountSignUpErrorMessage--a3166";
export var createAccountSignUpServerError = "CreateAccount-module--createAccountSignUpServerError--5ba40";
export var creatorSignUpcontainer = "CreateAccount-module--creatorSignUpcontainer--e8f07";