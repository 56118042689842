import React, { useEffect, useMemo, useState } from "react"

import { SubmitHandler, useForm } from "react-hook-form"

import FormLayout from "../../components/FormLayout/FormLayout"

import { CreateAccountInputs } from "../../../types/pages/create-account"
import * as styles from "./CreateAccount.module.scss"
import { CustomTextInput } from "../../components/CustomTextInput/CustomTextInput"
import { useWallet } from "use-wallet"
import { ConnectWalletMessage } from "../../components/ConnectWalletMessage/ConnectWalletMessage"
import Seo from "../../components/Seo/seo"
import { SignUpButton } from "../../components/SignUpButton/SignUpButton"
import { yupResolver } from "@hookform/resolvers/yup"
import { schema } from "../../validation/signups/user"
import { navigate } from "gatsby"
import { UserNameInput } from "../../components/UserNameInput/UserNameInput"
import { ProgressBar } from "../../components/ProgressBar/ProgressBar"
import {
  ServerErrorMessage,
  ErrorType,
  SignUpType,
} from "../../components/ServerErrorMessage/ServerErrorMessage"
import { fromJSON } from "../../lib/form-data"
import { PasswordRequirements } from "../../components/PasswordRequirements/PasswordRequirements"
import InputUploadImage from "../../components/InputUploadImage/InputUploadImage"

const CreateAccount = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CreateAccountInputs>({
    resolver: yupResolver(schema),
    mode: "all",
  })

  const [isLoading, setIsLoading] = useState(false)
  const wallet = useWallet()
  const [showServerError, setShowErrorMessage] = useState(false)
  const [serverErrorType, setServerErrorType] = useState<ErrorType>(
    ErrorType.Custom
  )

  useMemo(() => {
    if (wallet.account) {
      setValue("walletAddress", wallet.account)
    }
  }, [wallet.account])

  const onSubmit: SubmitHandler<CreateAccountInputs> = async (data, event) => {
    event?.preventDefault()
    if (wallet.status !== "connected") {
      return
    }

    try {
      setIsLoading(true)
      setShowErrorMessage(false)
      const uri = new URL("/auth", process.env.GATSBY_API_URL).toString()
      const formData = fromJSON(data)

      const response = await fetch(uri, {
        method: "POST",
        body: formData,
      })
      if (response.ok) {
        navigate("/sign-up/confirmation")
      } else {
        const res = await response.json()
        if (res.statusCode >= 500) {
          setServerErrorType(ErrorType.Unknown)
        } else {
          setServerErrorType(ErrorType.Exist)
        }
        setShowErrorMessage(true)
      }
    } catch (ex) {
      console.error(ex)
      setShowErrorMessage(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 75)
  }, [])

  return (
    <>
      <Seo title="Collector Sign up" />
      {isLoading && <ProgressBar />}
      <div className={styles.creatorSignUpcontainer}>
        <FormLayout
          title="Collector Sign Up"
          onSubmit={handleSubmit(onSubmit)}
          icon="collectorSquare"
          className={styles.createAccountForm}
          backgroundClassName={styles.createAccountFormBackground}
        >
          <div className={styles.createAccount}>
            <CustomTextInput
              errorMessage={errors.firstName?.message}
              placeholder="First Name"
              register={register("firstName")}
            />
            <CustomTextInput
              errorMessage={errors.middleName?.message}
              placeholder="Middle Name"
              isOptional={true}
              register={register("middleName")}
            />
            <CustomTextInput
              errorMessage={errors.lastName?.message}
              placeholder="Last Name"
              register={register("lastName")}
            />
            <CustomTextInput
              errorMessage={errors.email?.message}
              placeholder="Email"
              register={register("email", {
                required: "Please enter your email",
              })}
            />
            <InputUploadImage
              register={register("profilePicture")}
              setValue={setValue}
              errors={errors}
              name={"profilePicture"}
              errorMessage={errors.profilePicture?.message}
              maxFiles={1}
              subtitle={"Profile Picture (1 example, 15mb limit)"}
              title={"Drag and drop your profile picture here."}
            />
            <InputUploadImage
              register={register("coverImage")}
              setValue={setValue}
              errors={errors}
              name={"coverImage"}
              errorMessage={errors.coverImage?.message}
              maxFiles={1}
              subtitle={"Cover Image (1 example, 15mb limit)"}
              title={"Drag and drop your Cover Image here."}
            />
          </div>
          <div className={styles.createAccountButton}>
            <UserNameInput
              errorMessage={errors.username?.message}
              placeholder="Username"
              register={register("username")}
            />
            <div className={styles.createAccountPasswordContainer}>
              <CustomTextInput
                errorMessage={errors.password?.message}
                placeholder="Password"
                type="password"
                register={register("password")}
              />
              <PasswordRequirements
                classname={styles.createAccountPasswordRequirements}
              />
              <CustomTextInput
                errorMessage={errors.confirmPassword?.message}
                placeholder="Re-enter Password"
                type="password"
                register={register("confirmPassword")}
              />
            </div>
            <div>
              <ConnectWalletMessage />
            </div>
          </div>
          <div></div>
          <div className={styles.createAccountSignUpButtons}>
            <SignUpButton
              title={"→ Sign Up"}
              spanText={"All fields are required."}
              isDisabled={
                wallet.status !== "connected" ||
                Object.keys(errors).length > 0 ||
                isLoading
              }
              // classname={styles.createAccountSignUpButtons}
            />

            {showServerError && (
              <ServerErrorMessage
                errorType={serverErrorType}
                signuptype={SignUpType.Collector}
                errorTitle={"Submission denied: Account already created"}
                errorDetail={"Your DAFAx account can already collect artworks!"}
                className={styles.createAccountSignUpServerError}
              />
            )}
          </div>
        </FormLayout>
      </div>
    </>
  )
}

export default CreateAccount
