import * as yup from "yup"
import { contactNumber } from "./shared"

export const schema = yup
  .object()
  .shape({
    firstName: yup.string().trim().required("Please enter your name"),
    middleName: yup.string().trim(),
    lastName: yup.string().trim().required("Please enter your last name"),
    email: yup
      .string()
      .trim()
      .email("Please enter a valid email address")
      .required("Please enter your email address"),
    password: yup
      .string()
      .trim()
      .min(10, "Password must be 10 characters or longer")
      .matches(/^(?=.*[a-z])/, "Must Contain at least one lowercase character")
      .matches(/^(?=.*[A-Z])/, "Must Contain at least one uppercase character")
      .matches(
        /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
        "Must Contain at least one symbol"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain at least one number")
      .required(),
    confirmPassword: yup
      .string()
      .trim()
      .oneOf(
        [yup.ref("password"), null],
        "Password and Confirmation must match"
      ),
    username: yup.string().trim().required("Please enter your username"),
    walletAddress: yup.string().trim().required(),
  })
  .required()
